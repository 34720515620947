import React from 'react';

function encode(data: any) {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
  event.preventDefault();
  const fd = new FormData(event.target as HTMLFormElement);
  // remove bot field
  const tuple = [...fd.entries()].filter((_, index) => index !== 1);
  const data = Object.fromEntries(tuple);

  fetch('/', {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: encode({
      ...data,
    }),
  })
    .then((response) => response.json())
    .catch((error) => console.error(error.message));
};

export default () => (
  <form data-netlify name="roi" netlify-honeypot="bot-field" method="POST" onSubmit={(e) => handleSubmit(e)}>
    <input type="hidden" name="form-name" value="roi" />
    <input name="bot-field" type="hidden" />
    <input id="email" type="email" name="email" />
    <input id="username" type="text" name="username" />
    <input id="min" type="text" name="min" />
    <input id="max" type="text" name="max" />
    <input id="revenue" type="text" name="revenue" />
    <button type="submit">Submit</button>
  </form>
);
